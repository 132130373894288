import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import { getSlug } from '../helper'
import BreadcrumbSection from '../components/breadcrumb'
import ShowAllUseCaseBlock from '../components/Blocks/ShowAllUseCaseBlock'
import HeroBlock from '../components/Blocks/HeroBlock'
import PopupSchedule from "../components/popupSchedule/PopupSchedule"

const UseCaseType = ({ data, location }) => {
    const { wpTaxUseCaseType } = data
    const { cfUseCasesTypes, cptUseCases } = wpTaxUseCaseType
    const usecaseSlug = getSlug(cptUseCases.nodes[0].uri)
    const { titleTag, metaDescription, canonicalTagOverride, robotsDirective } = cfUseCasesTypes.typeSeoFields
    const { ogTitle, ogDescription, ogImage, ogType, twitterCard } = cfUseCasesTypes.typeSocialOpenGraphFields
    const breadcrumbItems = [
        {
            name: 'Use Cases',
            url: usecaseSlug,
        },
        {
            name: `Category: ${wpTaxUseCaseType.name}`,
        }

    ]

    const canonical = process.env.GATSBY_SITE_URL + location.pathname

    const [showSchedule, setShowSchedule] = useState(false)

    useEffect(() => {
        if (showSchedule) {
            document.querySelector('html').setAttribute('style', 'overflow:hidden')
        } else {
            document.querySelector('html').setAttribute('style', 'overflow:initial')
        }
    }, [showSchedule])

    useEffect(() => {
        if (window.location.href.includes("view-demo=1")) {
            setShowSchedule(true);
        }
    }, []);

    return (
        <Layout>
            <SEO
                title={titleTag}
                description={metaDescription}
                canonical={canonicalTagOverride || canonical}
                robotsDirective={robotsDirective}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage={ogImage}
                ogType={ogType}
                twitterCard={twitterCard}
                classBody="listener-type"
                breadcrumbItems={breadcrumbItems}
            />
            <BreadcrumbSection breadcrumbItems={breadcrumbItems} />
            <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
            {cfUseCasesTypes.headline && (
                <section className="intro-category">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <HeroBlock
                                    positioning="Center"
                                    width="Large"
                                    h1={cfUseCasesTypes.headline}
                                    subText={cfUseCasesTypes.body}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            )}
            <section>
                <div className="container">
                    <ShowAllUseCaseBlock
                        headline={cfUseCasesTypes.headlineUseCaseList ? cfUseCasesTypes.headlineUseCaseList : wpTaxUseCaseType.name + ' Use Cases'}
                        headlineType={cfUseCasesTypes.headline ? 'H3' : 'H1'} headlineSearch={cfUseCasesTypes.headlineSearch ? cfUseCasesTypes.headlineSearch : ''}
                        usecasesPost={cptUseCases.nodes}
                        isUseCaseTypePage={true}
                    />
                </div>
            </section>
        </Layout>
    )
}

export default UseCaseType
export const pageQuery = graphql`
     query PageUseCaseTypeTemplate($slug: String!) {
        allWpTaxUseCaseType {
            edges {
                node {
                    slug
                    name
                    cptUseCases {
                        nodes {
                            title
                        }
                    }
                }
            }
        }
        wpTaxUseCaseType(slug: {eq: $slug}) {
            cptUseCases {
                nodes {
                    cfUseCase {
                        mainElements {
                            h1
                            listenerExcerpt
                            slug
                            excerptTitle
                            useCaseExcerpt
                            usecaseNamePlural
                            usecaseNameSingular
                            excerptImage {
                                ...WordpressImageFields
                            }
                        }
                    }
                    uri
                }
            }
            slug
            name
            cfUseCasesTypes {
                headline
                body
                headlineUseCaseList
                headlineSearch
                typeSeoFields {
                    canonicalTagOverride
                    metaDescription
                    robotsDirective
                    titleTag
                }
                typeSocialOpenGraphFields {
                    ogDescription
                    ogTitle
                    ogType
                    twitterCard
                    ogImage {
                        ...WordpressImageFields
                    }
                }
            }
        }
    }
`
